.w-100 {
  width: auto !important;
}

body {
  font-family: Inter;
  /* background-color: #ffe8e829; */
}

.main-container-div {
  max-width: 500px;
  height: 600px;
  position: relative;
  /* background-color: #000 !important; */
  margin-top: 0px;
  padding: 0px !important;
}

/* background-color: #4b4e50 !important; */
.section-add-field {
  background-color: #4b4e50;
  /* background-color: #86C85D; */
  position: fixed;
  bottom: 0px;
  border-radius: 40px 40px 0px 0px;
  padding: 20px 30px;
  left: 0;
  flex-wrap: wrap;
  max-height: 140px;
  z-index: 23333333;
  overflow-y: scroll;
}

p {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: #fff;
  font-size: 18px;
}

.section-add-field {
  display: flex;
  gap: 10px;
  row-gap: 10px;
}

.btn-add-section {
  padding: 10px;
  /* background-color: #fff; */
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.header-add-order {
  background-color: #4b4e50;
  box-shadow: 0px 1px 9px #aaaaaa;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}

.header-add-order div {
  display: flex;
  gap: 15px;
  align-items: center;
}

.header-add-order a,
.header-add-order p {
  font-size: 24px;
}

.header-add-order p {
  color: #86C85D
}

.header-add-order img {
  width: 24px;
}

.header-add-order a {
  color: #5cb2f3;
}

.switch-personal-info p {
  flex: 1;
  /* This makes each <p> take up equal space */
  text-align: center;
  /* Center text horizontally */
  color: slategray;
  border-radius: 25px;
  padding: 20px;
}

.switch-personal-info {
  margin: 20px;
  display: flex;
  border: 1px solid #00000069;
  border-radius: 27px;
}

.personal-info {
  margin: 0px 20px;
  padding-bottom: 40px;
}

.Affiliation-info {
  margin-top: 25px;
}

.personal-info p,
.selected-Fields p {
  color: slategray;
}

.Affiliation-info p {
  border-bottom: 1px solid #00000069;
  font-size: 22px;
  font-weight: 600;
  line-height: 2;
}

.personal-info-input {
  margin-top: 25px;
}

.d-none {
  display: none !important;
}

.upload-image {
  padding: 40px;
  display: flex;
  justify-content: center;
  border: 2px dashed #00000069;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.upload-image img {
  width: 40px;
}

.switch-personal-info .active {
  background-color: #c0cdd6;
}

.selected-Fields p {
  text-align: center;
  margin-top: 30px !important;
}

.selected-Fields h4 {
  margin: 0px;
}

.box-of-fields {
  border: 1px solid #00000069;
  padding: 15px 20px;
  border-radius: 25px;
  margin-bottom: 20px;
}

.selected-Fields {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 2px dashed #00000069;
  padding-bottom: 220px !important;
}

.select-type-menu {
  margin-top: 20px !important;
}

.selet-type {
  padding: 20px;
}

.select-text label {
  color: black;
  font-size: 20px !important;
}

.deleteImageDiv {
  display: flex;
}

.multiple-images-preview {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.div-with-remove{
  display: flex;
  justify-content: space-between;
}
.btn-add-section img{
  width :24px;
  height :24px;
}